import React, { useState } from 'react';
import {
	Modal,
	ModalBody,
	ModalHeader,
	ModalTitle,
	Select,
	SelectOption,
} from '@citrite/citrix-ui';
import { cx } from '@emotion/css';
import { useThreatDashboardContext } from '../../contexts/ThreatDashboardContext';
import { TimeFilter } from '../../data/diagnosticTypes';
import { t } from '../../utils';
import { ComingSoonIllustration } from './ComingSoonIllustration';
import { FolderActivityContent } from './FolderActivityContent';
import { ActivityModalStyles } from './styles';
import { UserActivityModalWrapperProps } from './UserActivityModalWrapper';

export interface UAMProps extends UserActivityModalWrapperProps {
	onClose: () => void;
}

export const UserActivityModal = (props: UAMProps) => {
	const timeOptions = [
		{
			value: TimeFilter.Last24Hours.toString(),
			label: t('threat-visibility:diagnostics:last_24_hours'),
		} as SelectOption,
		{
			value: TimeFilter.Last7Days.toString(),
			label: t('threat-visibility:diagnostics:last_7_days'),
		} as SelectOption,
		{
			value: TimeFilter.Last30Days.toString(),
			label: t('threat-visibility:diagnostics:last_30_days'),
		} as SelectOption,
	];
	const managedUser = useThreatDashboardContext().managedUser;
	const [timeRange, setTimeRange] = useState(timeOptions[0].value);

	const getActivityDays = () => {
		if (timeRange == TimeFilter.Last24Hours.toString()) {
			return TimeFilter.Last24Hours;
		} else if (timeRange == TimeFilter.Last7Days.toString()) {
			return TimeFilter.Last7Days;
		} else {
			return -1;
		}
	};

	return (
		<Modal {...props} onDismiss={props.onClose} className={cx(ActivityModalStyles)}>
			<ModalHeader onDismiss={props.onClose}>
				<ModalTitle>
					{t('threat-visibility:diagnostics:activity_modal_header', {
						name: managedUser.FullName,
					})}
				</ModalTitle>
			</ModalHeader>
			<div
				className="filterDiv"
				data-testid="filterDiv"
				data-pendo-id={'Select' + timeRange + 'AnalyticsPilet'}
			>
				<Select
					data-testid="timeRangeFilter"
					options={timeOptions}
					value={timeRange || ''}
					onChange={setTimeRange}
				/>
			</div>
			<ModalBody className="activityContent">
				{timeRange === TimeFilter.Last30Days.toString() ? (
					<ComingSoonIllustration />
				) : (
					<FolderActivityContent ActivityDays={getActivityDays()} />
				)}
			</ModalBody>
		</Modal>
	);
};
