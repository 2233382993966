import React from 'react';
import { t } from '../../utils';

export const UnusualLocationThreatDetails = () => {
	return (
		<div data-testid="unusualLocThreat">
			{t('threat-visibility:threat_dashboard.unusual_location')}
		</div>
	);
};
