import React from 'react';
import { LoginThreat } from '../../data/analyticsTypes';
import { t } from '../../utils';

export const UnusualLocationDeviceThreatDetails = (props: LoginThreat) => {
	return (
		<div data-testid="unusualDeviceAndLocationThreat">
			<div>{t('threat-visibility:threat_dashboard.unusual_location_device')}</div>
			<div className="subDetailText" data-testid="deviceDetails">
				{t('threat-visibility:threat_dashboard.device_name')}: {props.Device}
			</div>
		</div>
	);
};
