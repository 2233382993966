import React from 'react';
import { TabContextProps } from '@sharefiledev/sharefile-appshell';
import { useShareFileUserContext } from '../../contexts/shareFileUserContext';
import { useThreatDashboardContext } from '../../contexts/ThreatDashboardContext';
import { t } from '../../utils';
import { StyledActions, StyledTab } from './styles';
import { ThreatDashboard, ThreatDashboardProps } from './ThreatDashboard';
export interface ThreatDashboardContainerProps {
	tabContext: TabContextProps;
	ExtSlot: any;
}

export const ThreatDashboardContainer = (props: ThreatDashboardContainerProps) => {
	const dataContext = useThreatDashboardContext();

	const childProps: ThreatDashboardProps = {
		sfUserContext: useShareFileUserContext(),
		managedUser: dataContext.managedUser,
	};

	return (
		<StyledTab
			data-analytics-id="ActivityAndSecurityAlertAnalyticsPilet"
			tabContext={props.tabContext}
			value="threatVisibility"
			label={t('threat-visibility:threat_dashboard.activity_security_alert')}
		>
			<ThreatDashboard {...childProps} />
			{dataContext.isActionsEnabled && (
				<StyledActions data-testid="user-actions-pilet">
					<props.ExtSlot
						name="urn:sfextslot:sharefile-appshell:user-actions"
						params={{
							currentUser: dataContext.currentUser,
							managedUser: dataContext.managedUser,
							accountPreferences: dataContext.accountPreferences,
							currentUserHasAdminRole: dataContext.currentUserHasAdminRole,
							canAdminResetPassword: dataContext.canAdminResetPassword,
							employeesRoute: dataContext.employeesRoute,
							clientsRoute: dataContext.clientsRoute,
						}}
					/>
				</StyledActions>
			)}
		</StyledTab>
	);
};
