import React, { useState } from 'react';
import { FolderActivity } from '../../data/diagnosticTypes';
import { ActivityFolderItem } from './ActivityFolderItem';

export interface FolderActivityLeftPaneProps {
	OnFolderSelect: (folderId: string) => void;
	FolderActivities: Array<FolderActivity>;
}

export const FolderActivityLeftPane = (props: FolderActivityLeftPaneProps) => {
	const [selectedFolder, setSelectedFolder] = useState<string>(
		props.FolderActivities[0].FolderId
	);

	const onSelect = (folderId: string) => {
		props.OnFolderSelect(folderId);
		setSelectedFolder(folderId);
	};

	return (
		<div className="leftPane">
			{props.FolderActivities.map(folder => {
				return (
					<ActivityFolderItem
						key={folder.FolderId}
						isSelected={folder.FolderId === selectedFolder}
						Activity={folder}
						OnClickFolder={onSelect}
					/>
				);
			})}
		</div>
	);
};
