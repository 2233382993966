import React, { useEffect, useState } from 'react';
import { LoadingPrimary } from '@citrite/citrix-ui';
import { AxiosError } from 'axios';
import { useShareFileUserContext } from '../../contexts/shareFileUserContext';
import { useThreatDashboardContext } from '../../contexts/ThreatDashboardContext';
import { FolderActivity } from '../../data/diagnosticTypes';
import { AnalyticsClientService } from '../../data/services/analyticsClient';
import { t } from '../../utils';
import { ErrorState } from '../../utils/ErrorState/ErrorState';
import { FolderActivityLeftPane } from './FolderActivityLeftPane';
import { FolderActivityRightPane } from './FolderActivityRightPane';
import { NoActivityIllustration } from './NoActivityIllustration';

export interface FolderActivityContentProps {
	ActivityDays: number;
}

enum ModalState {
	loading,
	noActivity,
	errorLoading,
	errorLoadingWithRetry,
	activityFetchedSuccessfully,
}

export const FolderActivityContent = (props: FolderActivityContentProps) => {
	const { sfAccount } = useShareFileUserContext();
	const managedUser = useThreatDashboardContext().managedUser;
	const [viewState, setViewState] = useState(ModalState.loading);
	const [folderActivities, setFolderActivities] = useState<Array<FolderActivity>>(
		Array<FolderActivity>()
	);
	const [selectedFolder, setSelectedFolder] = useState<string>('');

	useEffect(
		() => {
			fetchFolders();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.ActivityDays]
	);

	const fetchFolders = () => {
		AnalyticsClientService.getFoldersActivityCount(
			managedUser.Id,
			sfAccount?.Id,
			props.ActivityDays
		)
			.then(response => {
				if (response == null || typeof response === 'undefined') {
					setViewState(ModalState.errorLoading);
					return;
				}

				if (response.length == 0) {
					setViewState(ModalState.noActivity);
					return;
				}

				setFolderActivities(response);
				setSelectedFolder(response[0].FolderId);
				setViewState(ModalState.activityFetchedSuccessfully);
			})
			.catch(ex => {
				var exception = ex as AxiosError;

				if (
					exception.response?.status == 408 ||
					exception.response?.status == 502 ||
					exception.response?.status == 503 ||
					exception.response?.status == 504
				) {
					setViewState(ModalState.errorLoadingWithRetry);
				} else {
					setViewState(ModalState.errorLoading);
				}
			});
	};

	const getViewState = () => {
		switch (viewState) {
			case ModalState.loading:
				return <LoadingPrimary data-testid="loader" />;
			case ModalState.noActivity:
				return <NoActivityIllustration />;
			case ModalState.errorLoading:
				return (
					<ErrorState
						className="emptyStateArea"
						message={t('threat-visibility:diagnostics:error_loading_folders')}
					/>
				);
			case ModalState.errorLoadingWithRetry:
				return (
					<ErrorState
						className="emptyStateArea"
						message={t('threat-visibility:diagnostics:error_loading_folders_retry')}
						retryCallback={fetchFolders}
						retry={true}
					/>
				);
			case ModalState.activityFetchedSuccessfully:
				return (
					<>
						<FolderActivityLeftPane
							OnFolderSelect={setFolder}
							FolderActivities={folderActivities}
						/>
						<FolderActivityRightPane
							folderId={selectedFolder}
							ActivityDays={props.ActivityDays}
						/>
					</>
				);
		}
	};

	const setFolder = (folderId: string) => {
		setSelectedFolder(folderId);
	};

	return getViewState();
};
