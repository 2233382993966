import React from 'react';
import { FolderIcon } from '@citrite/citrix-ui';
import { FolderActivity } from '../../data/diagnosticTypes';
import { SimpleBreadcrumb } from '../../utils/Breadcrumbs/SimpleBreadcrumb';

export interface ActivityFolderItemProps {
	Activity: FolderActivity;
	isSelected: boolean;
	OnClickFolder: (activity: string) => void;
}

export const ActivityFolderItem = (props: ActivityFolderItemProps) => {
	return (
		<div
			data-testid="folderItem"
			className="folderItem"
			onClick={() => props.OnClickFolder(props.Activity.FolderId)}
			tabIndex={1}
			style={{
				backgroundColor: props.isSelected ? '#F3F3F3' : '#FFF',
				borderLeft: props.isSelected ? '4px solid #0045DB' : 'none',
			}}
		>
			<div className="folderInfo">
				<div className="folderDiv">
					<FolderIcon size={20} className="icon" data-testid="folderIcon" />
					<div
						className="folderName"
						data-testid="folderName"
						title={props.Activity.FolderName}
					>
						{props.Activity.FolderName}
					</div>
				</div>
				<SimpleBreadcrumb
					FolderId={props.Activity.FolderId}
					FolderName={props.Activity.FolderName}
					canNavigate={false}
				/>
			</div>
			<div className="folderActivityCount" data-testid="folderActivityCount">
				{props.Activity.ActivityCount}
			</div>
		</div>
	);
};
