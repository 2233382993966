import React, { useEffect, useState } from 'react';
import { AlertInfoIcon, Button, WarningStatusIcon } from '@citrite/citrix-ui';
import { cx } from '@emotion/css';
import { useShareFileUserContext } from '../../contexts/shareFileUserContext';
import { useThreatDashboardContext } from '../../contexts/ThreatDashboardContext';
import { AlertLevel, ThreatsCountData, TimeFilter } from '../../data/diagnosticTypes';
import { AnalyticsClientService } from '../../data/services/analyticsClient';
import { t } from '../../utils';
import { ActivityBannerStyles } from './styles';
import { UserActivityModalWrapper } from './UserActivityModalWrapper';

export const DefaultNumDaysForBanner = TimeFilter.Last7Days;

export const ActivityBanner = () => {
	const [countState, setCountState] = useState<ThreatsCountData>({} as ThreatsCountData);
	const [isLoading, setLoading] = useState<boolean>(true);
	const [showModal, setShowModal] = React.useState(false);
	const managedUser = useThreatDashboardContext().managedUser;
	const { sfAccount } = useShareFileUserContext();

	useEffect(() => {
		AnalyticsClientService.getThreatsCount(managedUser.Id, sfAccount?.Id)
			.then(response => {
				if (response == null || typeof response === 'undefined') {
					return;
				}

				setCountState(response);
			})
			.catch(() => {
				// Below is a conscious decision to show a default banner in case the count api fails
				// The configuration defaults to a banner text where we don't have alert count information...
				// ...just the view to navigate towards activities
				setCountState({
					ThreatsCount: 0,
					AlertLevel: AlertLevel.Warning,
					NumOfDays: DefaultNumDaysForBanner,
				} as ThreatsCountData);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [sfAccount?.Id, managedUser.Id]);

	const isAlertCondition = () => {
		return countState?.AlertLevel === AlertLevel.Alert;
	};

	return (
		<>
			{!isLoading && (
				<div
					className={cx(ActivityBannerStyles)}
					style={{ backgroundColor: isAlertCondition() ? '#FFF1E8' : '#E1F4FF' }}
				>
					<div className="bannerContent">
						{isAlertCondition() == false ? (
							<AlertInfoIcon size={24} className="icon" data-testid="alertInfoIcon" />
						) : (
							<WarningStatusIcon
								size={24}
								className="icon"
								data-testid="warningInfoIcon"
							/>
						)}
						<span className="bannerText">
							{!isAlertCondition()
								? t('threat-visibility:diagnostics:no_threats_banner')
								: t('threat-visibility:diagnostics:threats_banner', {
										alertCount: countState.ThreatsCount,
										numOfDays: countState.NumOfDays,
								  })}
						</span>
					</div>
					<Button.TextSecondary
						type="button"
						onClick={() => {
							setShowModal(true);
						}}
					>
						<div className="actionBtn" data-pendo-id="ViewFolderActivityAnalyticsPilet">
							{!isAlertCondition()
								? t('threat-visibility:diagnostics:view_activity')
								: t('threat-visibility:diagnostics:review_activity')}
						</div>
					</Button.TextSecondary>

					<div>
						{showModal && <UserActivityModalWrapper setShowModal={setShowModal} />}
					</div>
				</div>
			)}
		</>
	);
};
