import { UserPreferences } from '@citrite/sf-api';
import moment from 'moment';

type DateTypes = Date | moment.Moment | string;

export function formatDate(
	date: DateTypes,
	dateFormatOrPreferences: string | UserPreferences
) {
	let dateFormat: string | undefined;

	if (typeof dateFormatOrPreferences === 'string') {
		dateFormat = dateFormatOrPreferences;
	} else {
		dateFormat = dateFormatOrPreferences.DateFormat;
	}

	return formatWithLocale(date, dateFormat?.toUpperCase() ?? 'MM/DD/YYYY');
}

export function formatTime(
	date: DateTypes,
	timeFormatOrPreferences: string | UserPreferences
) {
	let timeFormat: string | undefined;

	if (typeof timeFormatOrPreferences === 'string') {
		timeFormat = timeFormatOrPreferences;
	} else {
		timeFormat = timeFormatOrPreferences.TimeFormat;
	}

	return formatWithLocale(date, normalizeTimeFormat(timeFormat));
}

export function formatDateTime(
	date: DateTypes,
	{ DateFormat, TimeFormat }: UserPreferences
) {
	const dateTimeFormat = `${DateFormat?.toUpperCase()} ${normalizeTimeFormat(
		TimeFormat
	)}`;
	return formatWithLocale(date, dateTimeFormat);
}

// const localesWithAlternateTime = ['ja', 'ko', 'zh-CN'];

function normalizeTimeFormat(format = 'h:mmt') {
	// if (format === 'h:mmt' && localesWithAlternateTime.includes(detectLanguage())) {
	//     return 'A h:mm';
	// }

	return format.replace('t', ' A');
}

function formatWithLocale(date: DateTypes, format: string) {
	// return moment(date).locale(detectLanguage()).format(format);

	return moment(date).format(format);
}
