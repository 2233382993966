export enum ThreatType {
	UNUSUAL_LOCATION_DEVICE = 1,
	UNUSUAL_LOCATION = 2,
	FREQUENT_LOGIN_FAILURE = 3,
	MALWARE_UPLOAD = 4,
}

export interface ThreatInfo {
	ThreatDetails: ThreatDetails;
	TimeStamp: Date;
	ThreatType: ThreatType;
}

export interface ThreatDetails {}

export interface LoginThreat extends ThreatDetails {
	Device: string | undefined;
	City: string | undefined;
	Country: string | undefined;
}

export interface MalwareThreat extends ThreatDetails {
	FileId: string;
	FileSize: number;
	FileName: string;
	FilePath: string;
	FolderName: string;
	FolderId: string;
	IsOwner: boolean;
}

export interface AccountPenetrationResponse {
	IsThreat: boolean;
}
