import { css } from '@emotion/css';

export const BreadcrumbStyles = css`
	font-size: 12px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	.folderPath {
		overflow: hidden;
		text-overflow: ellipsis;
		direction: rtl;

		&:hover {
			color: #5d5d5d;
			font-weight: 500;
			cursor: pointer;
		}
	}
`;
