import React, { useEffect, useState } from 'react';
import { ItemsEntity } from '@citrite/sf-api';
import { cx } from '@emotion/css';
import { useThreatDashboardContext } from '../../contexts/ThreatDashboardContext';
import { BouncingDotsLoader } from '../BouncingLoader/BouncingLoader';
import { BreadcrumbStyles } from './styles';

export interface SimpleBreadcrumbProps {
	FolderId: string;
	FolderName?: string;
	canNavigate?: boolean;
	className?: string;
}

export const SimpleBreadcrumb = (props: SimpleBreadcrumbProps) => {
	const [breadCrumbsPath, setBreadcrumbsPath] = useState<string>('');
	const [folderUrl, setFolderUrl] = useState<string>('');
	const [isLoadingPath, setLoadingPath] = useState(true);
	const getItemUrl = useThreatDashboardContext().getItemUrl;

	useEffect(() => {
		if (props.FolderId == null || typeof props.FolderId === 'undefined') {
			setLoadingPath(false);
			return;
		}

		const getFolderUrl = (folderId: string) => {
			if (typeof getItemUrl === 'undefined') {
				return `/home/findroute/${folderId}`;
			}

			return getItemUrl({ id: folderId });
		};
		ItemsEntity.getBreadcrumbs(props.FolderId, undefined, true)
			.execute()
			.then(breadCrumbs => {
				const breadCrumbPathArray = breadCrumbs.value?.map(item => item.Name);

				if (breadCrumbPathArray === undefined) {
					return;
				}

				setBreadcrumbsPath(getFolderPath(breadCrumbPathArray, props.FolderName || ''));

				if (props.canNavigate) {
					setFolderUrl(getFolderUrl(props.FolderId));
				}
			})
			.catch(() => {})
			.finally(() => {
				setLoadingPath(false);
			});
	}, [props.FolderId, props.FolderName, props.canNavigate, getItemUrl]);

	const getFolderPath = (
		breadcrumbPathArray: (string | undefined)[],
		folderName: string
	) => {
		const tempBreadcrumbPathArray = breadcrumbPathArray.slice(1);
		if (
			tempBreadcrumbPathArray.length === 1 &&
			tempBreadcrumbPathArray[0] === folderName
		) {
			return tempBreadcrumbPathArray[0];
		}
		tempBreadcrumbPathArray.push(folderName);
		return tempBreadcrumbPathArray.join(' > ');
	};

	const getContent = () => {
		return (
			<>
				{isLoadingPath === true ? (
					<BouncingDotsLoader />
				) : (
					<div className="folderPath" title={breadCrumbsPath}>
						{breadCrumbsPath}
					</div>
				)}
			</>
		);
	};

	return (
		<>
			{props.canNavigate ? (
				<a
					href={folderUrl}
					data-testid="navigableFolderPath"
					className={cx(BreadcrumbStyles) + ' ' + props.className}
				>
					{getContent()}
				</a>
			) : (
				<div
					data-testid="folderPath"
					className={cx(BreadcrumbStyles) + ' ' + props.className}
				>
					{getContent()}
				</div>
			)}
		</>
	);
};
