import React, { useEffect, useState } from 'react';
import { ItemIcon } from '@citrite/citrix-files-ui';
import { Item, ItemsEntity } from '@citrite/sf-api';
import { MalwareThreat } from '../../data/analyticsTypes';
import { t } from '../../utils';
import { SimpleBreadcrumb } from '../../utils/Breadcrumbs/SimpleBreadcrumb';

export const MalwareThreatDetails = (props: MalwareThreat) => {
	const [malwareFileItem, setFileItem] = useState<Item>({
		FileName: props.FileName,
	} as Item);

	useEffect(
		() => {
			ItemsEntity.get(props.FileId)
				.execute()
				.then(item => setFileItem(item));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.FolderId, props.FolderName]
	);

	return (
		<div data-testid="malwareThreat">
			<div>{t('threat-visibility:threat_dashboard.malware_upload')}</div>
			{props.IsOwner && (
				<>
					<div className="fileInfo" data-testid="fileInfo">
						<span data-testid="fileIcon">
							<ItemIcon item={malwareFileItem} fileName={props.FileName} size={16} />
						</span>
						<div data-testid="fileName" className="fileName">
							{props.FileName}
						</div>
					</div>
					<SimpleBreadcrumb
						className="subDetailText folderLink"
						FolderId={props.FolderId}
						FolderName={props.FolderName}
						canNavigate={true}
					/>
				</>
			)}
		</div>
	);
};
