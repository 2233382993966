import './ErrorState.scss';
import React from 'react';
import { Button, EmptyErrorIcon } from '@citrite/citrix-ui';
import { t } from '../translate';

export interface ErrorStateProps {
	message: string;
	retry?: boolean;
	retryCallback?: () => void;
	className: string;
}

export const ErrorState = (props: ErrorStateProps) => {
	return (
		<div className={props.className} data-testid="errorDiv">
			<EmptyErrorIcon size={160} />
			<div className="error-msg">{props.message}</div>
			{props.retry == true && (
				<Button.Primary onClick={props.retryCallback} type="button">
					{t('threat-visibility:threat_dashboard.retry')}
				</Button.Primary>
			)}
		</div>
	);
};
