import React, { useState } from 'react';
import { getFeatureFlagValue } from '@citrite/feature-flags';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { FeatureFlag } from '../../FeatureFlags/FeatureFlagDefinitions';
import { sendPendoTrackEvent } from '../Tracking/pendo';
import { SignInAgainModal } from './SignInAgainModal';

const oAuthTokenKey = 'OAuthToken';
const legacyTokenIss = 'ShareFile';

export const LegacyTokenInterceptor: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [showModal, setShowModal] = useState<boolean>(false);
	const legacyTokenDetected = React.useMemo(() => {
		const jwt = sessionStorage.getItem(oAuthTokenKey);
		if (!!jwt) {
			const { iss } = jwt_decode<JwtPayload>(jwt);
			if (iss === legacyTokenIss) {
				sendPendoTrackEvent('analytics_event_LegacyTokenDetected');
				return true;
			}
		}
		return false;
	}, []);

	if (
		getFeatureFlagValue(FeatureFlag.EnablePiletForcedAuth) &&
		legacyTokenDetected &&
		showModal === false
	) {
		setShowModal(true);
	}

	return (
		<>
			{children}
			{showModal && <SignInAgainModal setShowModal={setShowModal} />}
		</>
	);
};
