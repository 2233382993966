import React, { useState } from 'react';
import { Button, LoadingPrimary, notifyError } from '@citrite/citrix-ui';
import { SortParams } from '@citrite/sf-sorting';
import { cx } from '@emotion/css';
import { FailedAPIResponse } from '@sharefiledev/sharefile-appshell';
import { tryExtractErrorMessage } from '../javascript/messaging';
import { t } from '../translate';
import { pagingStyles } from './styles';

export interface PagingProps {
	itemCount: number;
	pageChildren(sortParams: SortParams): Promise<void>;
	sortParams: SortParams;
}

export const DefaultPageSize = 10;

export declare const sortDirections: {
	ASCENDING: 'asc';
	DESCENDING: 'desc';
};

export const Paging = (props: React.PropsWithChildren<PagingProps>) => {
	function showPaging(props: PagingProps) {
		return (
			props.sortParams.top !== -1 &&
			props.itemCount >=
				(props.sortParams.skip || 0) + (props.sortParams.pageSize || DefaultPageSize)
		);
	}

	const loadNextPage = async () => {
		setLoading(true);

		const params = {
			...props.sortParams,
			skip: (props.sortParams.skip || 0) + (props.sortParams.pageSize || DefaultPageSize),
		};

		await pageChildren(params);
	};

	const pageChildren = async (params: SortParams) => {
		try {
			await props.pageChildren(params);
		} catch (exception) {
			notifyError(
				tryExtractErrorMessage(
					exception as FailedAPIResponse,
					t('threat-visibility:general:error_message')
				)
			);
		}

		setLoading(false);
	};

	const [isLoading, setLoading] = useState(false);

	if (!showPaging(props)) {
		return null;
	}

	if (props.children) {
		return <div onClick={loadNextPage}>{props.children}</div>;
	}

	return (
		<div className={cx(pagingStyles)} data-testid="paging-div">
			{isLoading ? (
				<LoadingPrimary />
			) : (
				<Button.SubtlePrimary onClick={loadNextPage} data-testid="load-more-btn-div">
					<div className="loadMore">{t('threat-visibility:general:load_more')}</div>
				</Button.SubtlePrimary>
			)}
		</div>
	);
};
