import React, { useEffect, useState } from 'react';
import { WarningStatusIcon } from '@citrite/citrix-ui';
import { getFeatureFlagValue } from '@citrite/feature-flags';
import { cx } from '@emotion/css';
import { useShareFileUserContext } from '../../contexts/shareFileUserContext';
import { useThreatDashboardContext } from '../../contexts/ThreatDashboardContext';
import { AnalyticsClientService } from '../../data/services/analyticsClient';
import { FeatureFlag } from '../../FeatureFlags/FeatureFlagDefinitions';
import { t } from '../../utils';
import { ActivityBannerStyles } from '../diagnostics/styles';

export const AccountPenetrationBanner = () => {
	const managedUser = useThreatDashboardContext().managedUser;
	const { sfAccount } = useShareFileUserContext();
	const [isThreat, setIsThreat] = useState<boolean>(false);
	const isPenetrationThreatEnabled = getFeatureFlagValue(
		FeatureFlag.EnableMultiAccountPenetrationThreat
	);

	useEffect(() => {
		fetchData();
	});

	const fetchData = () => {
		if (isPenetrationThreatEnabled === false || isPenetrationThreatEnabled == null) {
			return;
		}

		AnalyticsClientService.getAccountsPenetrationStatus(managedUser.Id, sfAccount.Id)
			.then(response => {
				if (response == null || typeof response === 'undefined') {
					return;
				}

				setIsThreat(response.IsThreat);
			})
			.catch(() => {});
	};

	return (
		<>
			{isThreat && isPenetrationThreatEnabled && (
				<div
					className={cx(ActivityBannerStyles)}
					style={{ backgroundColor: '#FFF1E8' }}
					data-testid="penetration_banner"
				>
					<div className="bannerContent">
						<WarningStatusIcon size={24} className="icon" data-testid="warningInfoIcon" />
						<span className="bannerText">
							{t('threat-visibility:threat_dashboard.account_penetration')}
						</span>
					</div>
				</div>
			)}
		</>
	);
};
