/* tslint:disable */
/* This file was generated by @citrite/translate. Manual changes will be lost. */
import { Language } from '@citrite/translate';
export type locale =
	| 'de'
	| 'en'
	| 'es'
	| 'fr'
	| 'it'
	| 'ja'
	| 'ko'
	| 'nl'
	| 'pt-BR'
	| 'ru'
	| 'zh-CN';
export const languages: Language[] = [
	{
		key: 'de',
		content: {
			diagnostics: {
				action: 'Aktion',
				activity_modal_header: '{{name}} Ordneraktivität',
				coming_soon: 'Dieses Feature ist bald verfügbar!',
				error_loading_activity: 'Die Ordneraktivität kann nicht geladen werden',
				error_loading_activity_retry:
					'Die Ordneraktivität kann nicht geladen werden. Bitte versuchen Sie es später erneut',
				error_loading_folders: 'Ordner können nicht geladen werden',
				error_loading_folders_retry:
					'Ordner können nicht geladen werden. Bitte versuchen Sie es später erneut',
				file: 'Datei',
				last_24_hours: 'Letzten 24 Stunden',
				last_30_days: 'Letzte 30 Tage',
				last_7_days: 'Letzte 7 Tage',
				location: 'Speicherort',
				no_activity: 'In diesem Zeitrahmen gibt es keine Aktivität',
				no_activity_date: 'Keine Aktivität',
				no_threats_banner:
					'Zeigen Sie die Ordneraktivität dieses Benutzers an, um zu sehen, wie er ShareFile verwendet hat.',
				review_activity: 'Ordneraktivität überprüfen',
				threats_banner:
					'Dieser Benutzer hat {{alertCount}} Sicherheitswarnungen aus den letzten {{numOfDays}} Tagen. Überprüfen Sie die Ordneraktivität, um verdächtiges Verhalten festzustellen.',
				time: 'Uhrzeit',
				view_activity: 'Ordneraktivität anzeigen',
			},
			general: {
				error_message: 'Es ist ein Fehler aufgetreten',
				load_more: 'Weitere laden',
			},
			legacyTokenInterceptor: {
				buttonText: 'Anmelden',
				message:
					'Sie werden direkt hierher zurückgebracht, damit Sie dort weitermachen können, wo Sie aufgehört haben.',
				title:
					'Erneut anmelden, um auf das Dashboard der Aktivitäts- und Sicherheitsbenachrichtigungen zuzugreifen',
			},
			threat_dashboard: {
				account_penetration:
					'Eine ungewöhnliche Aktivität wurde in diesem Benutzerkonto erkannt',
				activity_security_alert: 'Aktivitäts- und Sicherheitswarnungen',
				date: 'Datum',
				details: 'Details',
				device_name: 'Gerätename',
				error_loading: 'Bedrohungen können nicht geladen werden',
				error_loading_permission: 'Sie sind nicht berechtigt, die Bedrohungen anzuzeigen',
				error_loading_retry:
					'Bedrohungen können nicht geladen werden. Bitte versuchen Sie es später erneut',
				frequent_login_failure: 'Mehrere fehlgeschlagene Anmeldeversuche',
				location: 'Speicherort',
				malware_upload: 'Versuchter Malware-Upload',
				retry: 'Wiederholen',
				unusual_location: 'Zugriff auf das Konto von einem ungewöhnlichen Standort aus',
				unusual_location_device:
					'Zugriff auf das Konto von einem ungewöhnlichen Standort aus mit einem unbekannten Gerät',
			},
		},
	},
	{
		key: 'en',
		content: {
			diagnostics: {
				action: 'Action',
				activity_modal_header: "{{name}}'s folder activity",
				coming_soon: 'This feature is coming soon!',
				error_loading_activity: 'Unable to load folder activity',
				error_loading_activity_retry:
					'Unable to load folder activity. Please try again later',
				error_loading_folders: 'Unable to load folders',
				error_loading_folders_retry: 'Unable to load folders. Please try again later',
				file: 'File',
				last_24_hours: 'Last 24 Hours',
				last_30_days: 'Last 30 Days',
				last_7_days: 'Last 7 days',
				location: 'Location',
				no_activity: "There's no activity in this time frame",
				no_activity_date: 'No activity',
				no_threats_banner:
					"View this user's folder activity to see how they've been using ShareFile.",
				review_activity: 'Review folder activity',
				threats_banner:
					'This user has {{alertCount}} security alerts from the last {{numOfDays}} days. Review their folder activity to check for suspicious behavior.',
				time: 'Time',
				view_activity: 'View folder activity',
			},
			general: {
				error_message: 'An error occured',
				load_more: 'Load More',
			},
			legacyTokenInterceptor: {
				buttonText: 'Sign in',
				message: "We'll bring you right back here so you can pick up where you left off.",
				title: 'Sign in again to access the Activity and Security Alerts dashboard',
			},
			threat_dashboard: {
				account_penetration: 'We have detected anomalous activity in this user account',
				activity_security_alert: 'Activity and Security Alerts',
				date: 'Date',
				details: 'Details',
				device_name: 'Device Name',
				error_loading: 'Unable to load threats',
				error_loading_permission: "You don't have permissions to view the threats",
				error_loading_retry: 'Unable to load threats. Please try again later',
				frequent_login_failure: 'Multiple failed sign-in attempts',
				location: 'Location',
				malware_upload: 'Attempted malware upload',
				retry: 'Retry',
				unusual_location: 'Account accessed from an unusual location',
				unusual_location_device:
					'Account accessed from an unusual location with an unfamiliar device',
			},
		},
	},
	{
		key: 'es',
		content: {
			diagnostics: {
				action: 'Acción',
				activity_modal_header: 'Actividad de la carpeta de {{name}}',
				coming_soon: 'Esta función estará disponible próximamente.',
				error_loading_activity: 'No se puede cargar la actividad de la carpeta',
				error_loading_activity_retry:
					'No se puede cargar la actividad de la carpeta. Inténtelo de nuevo más tarde',
				error_loading_folders: 'No se pueden cargar las carpetas',
				error_loading_folders_retry:
					'No se pueden cargar las carpetas. Inténtelo de nuevo más tarde',
				file: 'Archivo',
				last_24_hours: 'Últimas 24 horas',
				last_30_days: 'Últimos 30 días',
				last_7_days: 'Últimos 7 días',
				location: 'Ubicación',
				no_activity: 'No hay actividad en este período de tiempo',
				no_activity_date: 'No hay actividad',
				no_threats_banner:
					'Consulte la actividad de la carpeta de este usuario para ver cómo ha estado usando ShareFile.',
				review_activity: 'Revisar la actividad de la carpeta',
				threats_banner:
					'Este usuario tiene {{alertCount}} alertas de seguridad de los últimos {{numOfDays}} días. Revise la actividad de su carpeta para detectar si hay algún comportamiento sospechoso.',
				time: 'Hora',
				view_activity: 'Ver la actividad de la carpeta',
			},
			general: {
				error_message: 'Se ha producido un error',
				load_more: 'Cargar más',
			},
			legacyTokenInterceptor: {
				buttonText: 'Iniciar sesión',
				message: 'Le traeremos de regreso aquí para que pueda continuar donde lo dejó.',
				title:
					'Inicie sesión de nuevo para acceder al panel de mandos Alertas de actividad y seguridad',
			},
			threat_dashboard: {
				account_penetration:
					'Hemos detectado actividad anómala en esta cuenta de usuario',
				activity_security_alert: 'Alertas de actividad y seguridad',
				date: 'Fecha',
				details: 'Detalles',
				device_name: 'Nombre del dispositivo',
				error_loading: 'No se pueden cargar las amenazas',
				error_loading_permission: 'No tiene permisos para ver las amenazas',
				error_loading_retry:
					'No se pueden cargar las amenazas. Inténtelo de nuevo más tarde',
				frequent_login_failure: 'Varios intentos fallidos de inicio de sesión',
				location: 'Ubicación',
				malware_upload: 'Intento de carga de malware',
				retry: 'Reintentar',
				unusual_location: 'Se accedió a la cuenta desde una ubicación inusual',
				unusual_location_device:
					'Se accedió a la cuenta desde una ubicación inusual con un dispositivo desconocido',
			},
		},
	},
	{
		key: 'fr',
		content: {
			diagnostics: {
				action: 'Action',
				activity_modal_header: 'Activité des dossiers de {{name}}',
				coming_soon: 'Cette fonctionnalité sera bientôt disponible',
				error_loading_activity: "Impossible de charger l'activité des dossiers",
				error_loading_activity_retry:
					"Impossible de charger l'activité des dossiers. Réessayez plus tard.",
				error_loading_folders: 'Impossible de charger les dossiers',
				error_loading_folders_retry:
					'Impossible de charger les dossiers. Réessayez plus tard.',
				file: 'Fichier',
				last_24_hours: 'Dernières 24 heures',
				last_30_days: 'Les 30 derniers jours',
				last_7_days: '7 derniers jours',
				location: 'Emplacement',
				no_activity: "Il n'y a eu aucune activité pendant cette période",
				no_activity_date: 'Aucune activité',
				no_threats_banner:
					"Consultez l'activité des dossiers de cet utilisateur pour savoir comment il utilise ShareFile.",
				review_activity: 'Vérifier activité des dossiers',
				threats_banner:
					'Cet utilisateur a reçu {{alertCount}} alertes de sécurité au cours des {{numOfDays}} derniers jours. Examinez l’activité de leur dossier pour vérifier tout comportement suspect.',
				time: 'Heure',
				view_activity: 'Afficher activité des dossiers',
			},
			general: {
				error_message: 'Une erreur s’est produite',
				load_more: 'Charger plus',
			},
			legacyTokenInterceptor: {
				buttonText: 'Connexion',
				message:
					'Nous vous ramènerons ici pour que vous puissiez reprendre là où vous vous étiez arrêté.',
				title:
					"Connectez-vous à nouveau pour accéder au tableau de bord des alertes d'activité et de sécurité",
			},
			threat_dashboard: {
				account_penetration:
					'Nous avons détecté une activité anormale dans ce compte utilisateur',
				activity_security_alert: "Alertes d'activité et de sécurité",
				date: 'Date',
				details: 'Détails',
				device_name: "Nom de l'appareil",
				error_loading: 'Impossible de charger les menaces',
				error_loading_permission: "Vous n'êtes pas autorisé à afficher les menaces",
				error_loading_retry: 'Impossible de charger les menaces. Réessayez plus tard.',
				frequent_login_failure: 'Multiples tentatives de connexion infructueuses',
				location: 'Emplacement',
				malware_upload: 'Tentative de chargement de malware',
				retry: 'Réessayer',
				unusual_location: 'Accès au compte depuis un emplacement inhabituel',
				unusual_location_device:
					'Accès au compte depuis un emplacement inhabituel avec un appareil inconnu',
			},
		},
	},
	{
		key: 'it',
		content: {
			diagnostics: {
				action: 'Azione',
				activity_modal_header: 'Attività della cartella di {{name}}',
				coming_soon: 'Questa funzione sarà disponibile a breve',
				error_loading_activity: "Impossibile caricare l'attività della cartella",
				error_loading_activity_retry:
					"Impossibile caricare l'attività della cartella. Riprovare più tardi",
				error_loading_folders: 'Impossibile caricare le cartelle',
				error_loading_folders_retry:
					'Impossibile caricare le cartelle. Riprovare più tardi',
				file: 'File',
				last_24_hours: 'Ultime 24 ore',
				last_30_days: 'Ultimi 30 giorni',
				last_7_days: 'Ultimi 7 giorni',
				location: 'Posizione',
				no_activity: 'Non sono presenti attività in questo intervallo di tempo',
				no_activity_date: 'Nessuna attività',
				no_threats_banner:
					"Visualizzare l'attività della cartella dell'utente per vedere come ha usato ShareFile.",
				review_activity: "Esaminare l'attività delle cartelle",
				threats_banner:
					"Questo utente ha ricevuto {{alertCount}} avvisi di sicurezza negli ultimi {{numOfDays}} giorni. Esaminare l'attività della cartella per verificare eventuali comportamenti sospetti.",
				time: 'Ora',
				view_activity: "Visualizza l'attività delle cartelle",
			},
			general: {
				error_message: 'Si è verificato un errore',
				load_more: 'Carica altro',
			},
			legacyTokenInterceptor: {
				buttonText: 'Accedi',
				message:
					'Ti riporteremo qui in modo che tu possa riprendere da dove eri rimasto.',
				title:
					"Effettuare nuovamente l'accesso per accedere alla dashboard degli avvisi di sicurezza e attività",
			},
			threat_dashboard: {
				account_penetration: 'È stata rilevata attività anomala in questo account utente',
				activity_security_alert: 'Avvisi di attività e sicurezza',
				date: 'Data',
				details: 'Dettagli',
				device_name: 'Nome dispositivo',
				error_loading: 'Impossibile caricare le minacce',
				error_loading_permission:
					'Non si dispone delle autorizzazioni per visualizzare le minacce',
				error_loading_retry: 'Impossibile caricare le minacce. Riprovare più tardi',
				frequent_login_failure: 'Più tentativi di accesso non riusciti',
				location: 'Posizione',
				malware_upload: 'Tentativo di caricamento di malware',
				retry: 'Riprova',
				unusual_location: "Accesso all'account da una posizione insolita",
				unusual_location_device:
					"Accesso all'account da una posizione insolita con un dispositivo sconosciuto",
			},
		},
	},
	{
		key: 'ja',
		content: {
			diagnostics: {
				action: '操作',
				activity_modal_header: '{{name}} のフォルダー アクティビティ',
				coming_soon: 'この機能は近日公開予定です。',
				error_loading_activity: 'フォルダー アクティビティを読み込めません',
				error_loading_activity_retry:
					'フォルダー アクティビティを読み込めません。後でもう一度試してください',
				error_loading_folders: 'フォルダーを読み込めません',
				error_loading_folders_retry:
					'フォルダーを読み込めません。後でもう一度試してください',
				file: 'ファイル',
				last_24_hours: '過去 24 時間',
				last_30_days: '30日以内',
				last_7_days: '過去 7 日間',
				location: '場所',
				no_activity: 'この期間内にアクティビティはありません',
				no_activity_date: 'アクティビティがありません',
				no_threats_banner:
					'このユーザーのフォルダー アクティビティを表示して、ShareFile をどのように使用しているかを確認します。',
				review_activity: 'フォルダー アクティビティを確認する',
				threats_banner:
					'このユーザーには、過去 {{numOfDays}} 日間にセキュリティ警告が {{alertCount}} 件ありました。不審な動作がないかフォルダー アクティビティを確認します。',
				time: '時刻',
				view_activity: 'フォルダー アクティビティを表示する',
			},
			general: {
				error_message: 'エラーが発生しました',
				load_more: 'さらに読み込む',
			},
			legacyTokenInterceptor: {
				buttonText: 'サインイン',
				message: 'ここに戻りますので、中断したところから再開できます。',
				title:
					'アクティビティとセキュリティのアラート ダッシュボードにアクセスするには、再度サインインしてください',
			},
			threat_dashboard: {
				account_penetration:
					'このユーザー アカウントで異常なアクティビティを検出しました',
				activity_security_alert: 'アクティビティとセキュリティのアラート',
				date: '日付',
				details: '詳細',
				device_name: 'デバイス名',
				error_loading: '脅威を読み込めません',
				error_loading_permission: '脅威を表示する権限がありません',
				error_loading_retry: '脅威を読み込めません。後でもう一度試してください',
				frequent_login_failure: 'サインイン試行が複数回失敗しました',
				location: '場所',
				malware_upload: 'マルウェアをアップロードしようとしました',
				retry: '再試行',
				unusual_location: '通常とは異なる場所からアカウントにアクセスされました',
				unusual_location_device:
					'いつも使用していないデバイスで通常とは異なる場所からアカウントにアクセスされました',
			},
		},
	},
	{
		key: 'ko',
		content: {
			diagnostics: {
				action: '작업',
				activity_modal_header: '{{name}}님의 폴더 활동',
				coming_soon: '이 기능은 곧 제공될 예정입니다.',
				error_loading_activity: '폴더 활동을 로드할 수 없음',
				error_loading_activity_retry:
					'폴더 활동을 로드할 수 없습니다. 나중에 다시 시도하십시오.',
				error_loading_folders: '폴더를 로드할 수 없음',
				error_loading_folders_retry:
					'폴더를 로드할 수 없습니다. 나중에 다시 시도하십시오.',
				file: '파일',
				last_24_hours: '지난 24시간',
				last_30_days: '지난 30일',
				last_7_days: '지난 7일',
				location: '위치',
				no_activity: '이 기간에는 활동이 없습니다',
				no_activity_date: '활동 없음',
				no_threats_banner:
					'폴더 활동을 통해 이 사용자의 ShareFile 사용 현황을 확인할 수 있습니다.',
				review_activity: '폴더 활동 검토',
				threats_banner:
					'지난 {{numOfDays}}일간 이 사용자의 보안 알림이 {{alertCount}}건이었습니다. 폴더 활동을 검토하여 의심스러운 행동이 있는지 확인하십시오.',
				time: '시간',
				view_activity: '폴더 활동 보기',
			},
			general: {
				error_message: '오류 발생',
				load_more: '추가 로드',
			},
			legacyTokenInterceptor: {
				buttonText: 'Sign in',
				message: "We'll bring you right back here so you can pick up where you left off.",
				title: 'Sign in again to access the Activity and Security Alerts dashboard',
			},
			threat_dashboard: {
				account_penetration: 'We have detected anomalous activity in this user account',
				activity_security_alert: '활동 및 보안 알림',
				date: '날짜',
				details: '세부 정보',
				device_name: '장치 이름',
				error_loading: '위협을 로드할 수 없음',
				error_loading_permission: '위협을 확인할 권한이 없습니다',
				error_loading_retry: '위협을 로드할 수 없습니다. 나중에 다시 시도하십시오',
				frequent_login_failure: '로그인 시도에 여러 번 실패함',
				location: '위치',
				malware_upload: '악성 코드 업로드 시도',
				retry: '다시 시도',
				unusual_location: '비정상적인 위치에서 계정에 액세스함',
				unusual_location_device:
					'익숙하지 않은 장치를 사용하여 비정상적인 위치에서 계정에 액세스함',
			},
		},
	},
	{
		key: 'nl',
		content: {
			diagnostics: {
				action: 'Actie',
				activity_modal_header: 'Mapactiviteit van {{name}}',
				coming_soon: 'Deze functie is binnenkort beschikbaar!',
				error_loading_activity: 'Kan mapactiviteit niet laden',
				error_loading_activity_retry:
					'Kan mapactiviteit niet laden. Probeer het later opnieuw',
				error_loading_folders: 'Kan mappen niet laden',
				error_loading_folders_retry: 'Kan mappen niet laden. Probeer het later opnieuw',
				file: 'Bestand',
				last_24_hours: 'Laatste 24 uur',
				last_30_days: 'Laatste 30 dagen',
				last_7_days: 'Laatste 7 dagen',
				location: 'Locatie',
				no_activity: 'Er was geen activiteit in deze periode',
				no_activity_date: 'Geen activiteit',
				no_threats_banner:
					'Bekijk de mapactiviteit van deze gebruiker om te zien hoe hij/zij ShareFile heeft gebruikt.',
				review_activity: 'Mapactiviteit controleren',
				threats_banner:
					'Deze gebruiker heeft {{alertCount}} beveiligingswaarschuwingen ontvangen in de laatste {{numOfDays}} dagen. Controleer zijn/haar mapactiviteit met het oog op verdacht gedrag.',
				time: 'Tijd',
				view_activity: 'Mapactiviteit controleren',
			},
			general: {
				error_message: 'Er is een fout opgetreden',
				load_more: 'Meer laden',
			},
			legacyTokenInterceptor: {
				buttonText: 'Aanmelden',
				message:
					'U wordt zo meteen naar hier teruggeleid, zodat u verder kunt gaan waar u was gebleven.',
				title:
					'Meld u opnieuw aan om toegang te krijgen tot het dashboard Activiteits- en beveiligingswaarschuwingen',
			},
			threat_dashboard: {
				account_penetration:
					'We hebben abnormale activiteiten gedetecteerd in dit gebruikersaccount',
				activity_security_alert: 'Activiteits- en beveiligingswaarschuwingen',
				date: 'Datum',
				details: 'Details',
				device_name: 'Apparaatnaam',
				error_loading: 'Kan bedreigingen niet laden',
				error_loading_permission:
					'U bent niet gemachtigd om de bedreigingen weer te geven',
				error_loading_retry: 'Kan bedreigingen niet laden. Probeer het later opnieuw',
				frequent_login_failure: 'Meerdere mislukte aanmeldingspogingen',
				location: 'Locatie',
				malware_upload: 'Poging tot uploaden van malware',
				retry: 'Opnieuw proberen',
				unusual_location: 'Toegang tot account vanaf een ongebruikelijke locatie',
				unusual_location_device:
					'Toegang tot account vanaf een ongebruikelijke locatie met een onbekend apparaat',
			},
		},
	},
	{
		key: 'pt-BR',
		content: {
			diagnostics: {
				action: 'Ação',
				activity_modal_header: 'Atividade da pasta de {{name}}',
				coming_soon: 'Esta funcionalidade estará disponível em breve!',
				error_loading_activity: 'Não é possível carregar a atividade da pasta',
				error_loading_activity_retry:
					'Não é possível carregar a atividade da pasta. Tente novamente mais tarde',
				error_loading_folders: 'Não é possível carregar pastas',
				error_loading_folders_retry:
					'Não é possível carregar pastas. Tente novamente mais tarde',
				file: 'Arquivo',
				last_24_hours: 'Últimas 24 horas',
				last_30_days: 'Últimos 30 Dias',
				last_7_days: 'Últimos sete dias',
				location: 'Local',
				no_activity: 'Não há atividade neste período',
				no_activity_date: 'Sem atividades',
				no_threats_banner:
					'Exiba a atividade da pasta desse usuário para ver como ele tem usado o ShareFile.',
				review_activity: 'Revisar a atividade da pasta',
				threats_banner:
					'Este usuário tem {{alertCount}} alertas de segurança dos últimos {{numOfDays}} dias. Revise a atividade da pasta para verificar se há comportamento suspeito.',
				time: 'Horário',
				view_activity: 'Exibir atividade da pasta',
			},
			general: {
				error_message: 'Ocorreu um erro',
				load_more: 'Carregar mais',
			},
			legacyTokenInterceptor: {
				buttonText: 'Fazer login',
				message:
					'Nós o traremos de volta aqui para que você possa continuar de onde parou.',
				title:
					'Faça logon novamente para acessar o painel de Alertas de Atividade e Segurança',
			},
			threat_dashboard: {
				account_penetration: 'Detectamos atividade anormal nesta conta de usuário',
				activity_security_alert: 'Alertas de atividade e segurança',
				date: 'Data',
				details: 'Detalhes',
				device_name: 'Nome de dispositivo',
				error_loading: 'Não é possível carregar ameaças',
				error_loading_permission: 'Você não tem permissão para ver as ameaças',
				error_loading_retry:
					'Não é possível carregar ameaças. Tente novamente mais tarde',
				frequent_login_failure: 'Várias tentativas de login malsucedidas',
				location: 'Local',
				malware_upload: 'Tentativa de upload de malware',
				retry: 'Tentar novamente',
				unusual_location: 'Conta acessada de um local incomum',
				unusual_location_device:
					'Conta acessada de um local incomum com um dispositivo desconhecido',
			},
		},
	},
	{
		key: 'ru',
		content: {
			diagnostics: {
				action: 'Действие',
				activity_modal_header: 'Действия пользователя {{name}} в папках',
				coming_soon: 'Эта функция скоро появится!',
				error_loading_activity: 'Не удалось загрузить действия в папках',
				error_loading_activity_retry:
					'Не удалось загрузить активность папки. Повторите попытку позже',
				error_loading_folders: 'Не удалось загрузить папки',
				error_loading_folders_retry:
					'Не удалось загрузить папки. Повторите попытку позже',
				file: 'Файл',
				last_24_hours: 'Последние 24 часа',
				last_30_days: 'Последние 30 дней',
				last_7_days: 'Последние 7 дней',
				location: 'Расположение',
				no_activity: 'В этот период действий не было',
				no_activity_date: 'Нет действий',
				no_threats_banner:
					'Просмотрите действия этого пользователя в папках, чтобы узнать, как он использует ShareFile.',
				review_activity: 'Просмотр действий в папках',
				threats_banner:
					'Этот пользователь получил {{alertCount}} оповещ. системы безопасности за последние {{numOfDays}} дн. Проверьте действия пользователя в папках на предмет подозрительного поведения.',
				time: 'Время',
				view_activity: 'Просмотр действий в папках',
			},
			general: {
				error_message: 'Произошла ошибка',
				load_more: 'Загрузить еще',
			},
			legacyTokenInterceptor: {
				buttonText: 'Sign in',
				message: "We'll bring you right back here so you can pick up where you left off.",
				title: 'Sign in again to access the Activity and Security Alerts dashboard',
			},
			threat_dashboard: {
				account_penetration: 'We have detected anomalous activity in this user account',
				activity_security_alert: 'Действия и оповещения системы безопасности',
				date: 'Дата',
				details: 'Сведения',
				device_name: 'Имя устройства',
				error_loading: 'Не удалось загрузить угрозы',
				error_loading_permission: 'У вас нет разрешений на просмотр угроз',
				error_loading_retry: 'Не удалось загрузить угрозы. Повторите попытку позже',
				frequent_login_failure: 'Несколько неудачных попыток входа в систему',
				location: 'Расположение',
				malware_upload: 'Попытка загрузки вредоносного ПО',
				retry: 'Повторить попытку',
				unusual_location: 'Доступ к учетной записи из необычного места',
				unusual_location_device:
					'Доступ к учетной записи из необычного места с помощью незнакомого устройства',
			},
		},
	},
	{
		key: 'zh-CN',
		content: {
			diagnostics: {
				action: '操作',
				activity_modal_header: '{{name}} 的文件夹活动',
				coming_soon: '此功能即将推出!',
				error_loading_activity: '无法加载文件夹活动',
				error_loading_activity_retry: '无法加载文件夹活动。请稍后再试',
				error_loading_folders: '无法加载文件夹',
				error_loading_folders_retry: '无法加载文件夹。请稍后再试',
				file: '文件',
				last_24_hours: '过去 24 小时',
				last_30_days: '过去 30 天',
				last_7_days: '过去 7 天',
				location: '位置',
				no_activity: '这段时间内没有任何活动',
				no_activity_date: '无活动',
				no_threats_banner: '查看此用户的文件夹活动，了解其如何使用 ShareFile。',
				review_activity: '查看文件夹活动',
				threats_banner:
					'此用户过去 {{numOfDays}} 天有 {{alertCount}} 条安全警报。请查看其文件夹活动以检查是否存在可疑行为。',
				time: '时间',
				view_activity: '查看文件夹活动',
			},
			general: {
				error_message: '出现错误',
				load_more: '加载更多',
			},
			legacyTokenInterceptor: {
				buttonText: 'Sign in',
				message: "We'll bring you right back here so you can pick up where you left off.",
				title: 'Sign in again to access the Activity and Security Alerts dashboard',
			},
			threat_dashboard: {
				account_penetration: 'We have detected anomalous activity in this user account',
				activity_security_alert: '活动和安全警报',
				date: '日期',
				details: '详细信息',
				device_name: '设备名称',
				error_loading: '无法加载威胁',
				error_loading_permission: '您无权查看威胁',
				error_loading_retry: '无法加载威胁。请稍后再试',
				frequent_login_failure: '多次登录尝试失败',
				location: '位置',
				malware_upload: '尝试上载恶意软件',
				retry: '重试',
				unusual_location: '从异常位置访问了帐户',
				unusual_location_device: '使用不熟悉的设备从异常位置访问了帐户',
			},
		},
	},
];
