import { css } from '@emotion/css';

export const pagingStyles = css`
	height: 55px;
	font-size: 14px;
	color: #0045db;
	display: flex;
	align-items: center;
	justify-content: center;

	button {
		width: 100%;
		border-radius: 50%;
	}

	.loadMore {
		height: 40px;
		font-weight: 600;
		color: #0045db;
		display: flex;
		align-items: center;
		justify-content: center;
		align-items: center;
	}

	.loadMore > span {
		margin-right: 8px;
	}
`;
