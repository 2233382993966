import * as React from 'react';
import { Api } from '@citrite/sf-api';
import type { PiletApi } from '@sharefiledev/sharefile-appshell';
import { ShareFileUserContextProvider } from './contexts/shareFileUserContext';
import { ThreatDashboardContextProvider } from './contexts/ThreatDashboardContext';
import { setLogger } from './logger';
import { LegacyTokenInterceptor } from './utils/LegacyTokenInterceptor/LegacyTokenInterceptor';
import { ThreatDashboardContainer } from './views/threatDashboard/ThreatDashboardRequestContainer';

export function setup(app: PiletApi) {
	setLogger(app.sf.getLogger());
	app.sf.registerSfApiConfigHandler(Api.configure);

	app.registerExtension(
		'urn:sfextslot:sharefile-appshell:user-activity-history',
		({ params }) => {
			return (
				<ShareFileUserContextProvider {...app.sf.piletUserContext.get()}>
					<LegacyTokenInterceptor>
						<ThreatDashboardContextProvider {...params}>
							<ThreatDashboardContainer
								tabContext={params.tabContext}
								ExtSlot={params.ExtSlot}
							/>
						</ThreatDashboardContextProvider>
					</LegacyTokenInterceptor>
				</ShareFileUserContextProvider>
			);
		}
	);
}
