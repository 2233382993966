import { AccountPenetrationResponse, ThreatInfo } from '../analyticsTypes';
import {
	ActivityDetails,
	FolderActivity,
	FolderDayActivity,
	ThreatsCountData,
} from '../diagnosticTypes';
import { apiProxy, RequestConfig } from './apiClient';

export interface PagingParams {
	top: number;
	skip: number;
}

export namespace AnalyticsClientService {
	export function getThreats(
		userId: string | undefined,
		accountId: string | undefined,
		paging: PagingParams
	) {
		const requestConfig: RequestConfig = {
			path: `/threats/${accountId}/${userId}`,
			params: {
				PageSize: paging.top,
				PageOffset: paging.skip,
			},
			method: 'get',
		};

		return apiProxy<Array<ThreatInfo>>(requestConfig);
	}

	export function getThreatsCount(
		userId: string | undefined,
		accountId: string | undefined
	) {
		const requestConfig: RequestConfig = {
			path: `/threats/count/${accountId}/${userId}`,
			method: 'get',
		};

		return apiProxy<ThreatsCountData>(requestConfig);
	}

	export function getFoldersActivityCount(
		userId: string | undefined,
		accountId: string | undefined,
		numOfDays: number
	) {
		const requestConfig: RequestConfig = {
			path: `/activity/count/${accountId}/${userId}`,
			params: {
				days: numOfDays,
			},
			method: 'get',
		};

		return apiProxy<Array<FolderActivity>>(requestConfig);
	}

	export function getFolderActivity(
		userId: string | undefined,
		accountId: string | undefined,
		folderId: string | undefined,
		numOfDays: number
	) {
		const requestConfig: RequestConfig = {
			path: `/activity/${accountId}/${userId}/${folderId}`,
			params: {
				days: numOfDays,
			},
			method: 'get',
		};

		return apiProxy<Array<FolderDayActivity>>(requestConfig);
	}

	export function getFolderActivityForDate(
		userId: string | undefined,
		accountId: string | undefined,
		folderId: string | undefined,
		date: Date,
		paging: PagingParams
	) {
		const requestConfig: RequestConfig = {
			path: `/activity/${accountId}/${userId}/${folderId}/page`,
			params: {
				date: date.toLocaleDateString('en-GB', {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
				}),
				PageSize: paging.top,
				PageOffset: paging.skip,
			},
			method: 'get',
		};

		return apiProxy<Array<ActivityDetails>>(requestConfig);
	}

	export function getAccountsPenetrationStatus(
		userId: string | undefined,
		accountId: string | undefined
	) {
		const requestConfig: RequestConfig = {
			path: `/threats/accountspenetration/${accountId}/${userId}`,
			method: 'get',
		};

		return apiProxy<AccountPenetrationResponse>(requestConfig);
	}
}
