interface Window {
	pendo?: any;
}

// todo move this to piral appshell extension
export function sendPendoTrackEvent(event: string, metadata: any = {}) {
	try {
		const pendo = (window as unknown as Window).pendo;
		if (!pendo?.track) {
			return;
		}
		const metadataWithProductName = {
			...metadata,
			product: 'ShareFile - Analytics',
			type: event,
		};
		pendo.track(event, metadataWithProductName);
	} catch {}
}
