import React, { useEffect, useState } from 'react';
import {
	LoadingPrimary,
	sortDirections,
	Table,
	TBody,
	TD,
	THead,
} from '@citrite/citrix-ui';
import { createUserPreferences, User } from '@citrite/sf-api';
import { SortParams } from '@citrite/sf-sorting';
import { cx } from '@emotion/css';
import { AxiosError } from 'axios';
import { v4 } from 'uuid';
import { ShareFileUserContext } from '../../contexts/shareFileUserContext';
import { LoginThreat, ThreatInfo, ThreatType } from '../../data/analyticsTypes';
import {
	AnalyticsClientService,
	PagingParams,
} from '../../data/services/analyticsClient';
import { t } from '../../utils';
import { formatDateTime } from '../../utils/dateTimeformatters';
import { ErrorState } from '../../utils/ErrorState/ErrorState';
import { Paging } from '../../utils/Paging/Paging';
import { ActivityBanner } from '../diagnostics/ActivityBanner';
import { AccountPenetrationBanner } from './AccountPenetrationBanner';
import { EmptyTableIllustration } from './EmptyTableIllustration';
import { threatDashboardStyles } from './styles';
import { DetailedThreatInfo } from './ThreatDetails';

export interface ThreatDashboardProps {
	sfUserContext: ShareFileUserContext;
	managedUser: User;
}

enum DashboardState {
	loading,
	noThreats,
	errorLoading,
	errorLoadingWithPermission,
	errorLoadingWithRetry,
	threatsFetchedSuccessfully,
}

export const DefaultPageSizeForThreatDashboard = 25;

export const ThreatDashboard = (props: ThreatDashboardProps) => {
	const [threatsList, setThreatList] = useState<Array<ThreatInfo>>(Array<ThreatInfo>());
	const [dashboardViewState, setViewState] = useState(DashboardState.loading);
	const [sortParams, setSortParams] = useState<SortParams>({
		key: t('threat-visibility:threat_dashboard.date'),
		direction: sortDirections.descending,
		pageSize: DefaultPageSizeForThreatDashboard,
		skip: 0,
		top: DefaultPageSizeForThreatDashboard,
	});

	useEffect(
		() => {
			fetchThreats();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const fetchThreats = () => {
		setViewState(DashboardState.loading);

		AnalyticsClientService.getThreats(
			props.managedUser.Id,
			props.sfUserContext.sfAccount.Id,
			{
				top: sortParams.pageSize,
				skip: sortParams.skip,
			} as PagingParams
		)
			.then(threats => {
				if (threats === undefined || threats.length == 0) {
					setViewState(DashboardState.noThreats);
					return;
				}

				setThreatList([...threatsList, ...threats]);
				setViewState(DashboardState.threatsFetchedSuccessfully);
			})
			.catch(ex => {
				var exception = ex as AxiosError;

				if (
					exception.response?.status == 408 ||
					exception.response?.status == 502 ||
					exception.response?.status == 503 ||
					exception.response?.status == 504
				) {
					setViewState(DashboardState.errorLoadingWithRetry);
				} else if (exception.response?.status == 403) {
					setViewState(DashboardState.errorLoadingWithPermission);
				} else {
					setViewState(DashboardState.errorLoading);
				}
			});
	};

	function getLocationInfo(threat: ThreatInfo) {
		if (threat.ThreatType === ThreatType.MALWARE_UPLOAD) {
			return <div></div>;
		}

		return (
			<div>
				{(threat.ThreatDetails as LoginThreat).City},{' '}
				{(threat.ThreatDetails as LoginThreat).Country}
			</div>
		);
	}

	const pageChildren = async (sortParams: SortParams) => {
		const threats = await AnalyticsClientService.getThreats(
			props.managedUser.Id,
			props.sfUserContext.sfAccount.Id,
			{
				top: sortParams.pageSize,
				skip: sortParams.skip,
			} as PagingParams
		);

		setThreatList([...threatsList, ...threats]);
		setSortParams(sortParams);
	};

	const getViewState = () => {
		switch (dashboardViewState) {
			case DashboardState.loading:
				return <LoadingPrimary data-testid="loader" className="dashboardArea" />;
			case DashboardState.noThreats:
				return (
					<>
						<ActivityBanner />
						<AccountPenetrationBanner />
						<div className="dashboardArea">{EmptyTableIllustration()}</div>
					</>
				);
			case DashboardState.errorLoading:
				return (
					<ErrorState
						className="dashboardArea"
						message={t('threat-visibility:threat_dashboard.error_loading')}
					/>
				);
			case DashboardState.errorLoadingWithRetry:
				return (
					<ErrorState
						className="dashboardArea"
						message={t('threat-visibility:threat_dashboard.error_loading_retry')}
						retry={true}
						retryCallback={fetchThreats}
					/>
				);
			case DashboardState.errorLoadingWithPermission:
				return (
					<ErrorState
						className="dashboardArea"
						message={t('threat-visibility:threat_dashboard.error_loading_permission')}
					/>
				);
			case DashboardState.threatsFetchedSuccessfully:
				return (
					<>
						<ActivityBanner />
						<AccountPenetrationBanner />
						<div data-testid="threatDashboard">
							<Table>
								<THead>
									<th className="timestampColumn">
										{t('threat-visibility:threat_dashboard.date')}
									</th>
									<th className="threatDetailColumn">
										{t('threat-visibility:threat_dashboard.details')}
									</th>
									<th className="locationColumn">
										{t('threat-visibility:threat_dashboard.location')}
									</th>
								</THead>
								<TBody>
									{(threatsList as Array<ThreatInfo>).map(threat => {
										return (
											<tr key={v4()} className="threatRow">
												<TD label="Timestamp">
													{formatDateTime(
														threat.TimeStamp,
														props.sfUserContext.sfUser.Preferences ||
															createUserPreferences()
													)}
												</TD>
												<TD label="Details">
													<DetailedThreatInfo {...threat} />
												</TD>
												<TD label="Location">{getLocationInfo(threat)}</TD>
											</tr>
										);
									})}
								</TBody>
							</Table>
							<Paging
								itemCount={threatsList.length}
								sortParams={sortParams}
								pageChildren={pageChildren}
							/>
						</div>
					</>
				);
		}
	};

	return <div className={cx(threatDashboardStyles)}>{getViewState()}</div>;
};
