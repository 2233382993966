import React from 'react';
import { AccountPreferences, User } from '@citrite/sf-api';

export interface ItemObject {
	id: string;
}

export interface ThreatDashboardContext {
	isActionsEnabled: boolean;
	currentUser: User;
	managedUser: User;
	accountPreferences: AccountPreferences;
	currentUserHasAdminRole: boolean;
	canAdminResetPassword: boolean;
	employeesRoute: string;
	clientsRoute: string;
	getItemUrl: (param: ItemObject) => string;
}

interface ThreatDashboardContextProviderProps extends ThreatDashboardContext {
	children: React.ReactNode;
}

const Context = React.createContext<ThreatDashboardContext>({
	isActionsEnabled: false,
	currentUser: {},
	managedUser: {},
	accountPreferences: {},
	currentUserHasAdminRole: false,
	canAdminResetPassword: false,
	employeesRoute: '',
	clientsRoute: '',
	getItemUrl: () => '',
});

export function ThreatDashboardContextProvider(
	props: ThreatDashboardContextProviderProps
) {
	return (
		<Context.Provider
			value={{
				isActionsEnabled: props.isActionsEnabled,
				currentUser: props.currentUser,
				managedUser: props.managedUser,
				accountPreferences: props.accountPreferences,
				currentUserHasAdminRole: props.currentUserHasAdminRole,
				canAdminResetPassword: props.canAdminResetPassword,
				employeesRoute: props.employeesRoute,
				clientsRoute: props.clientsRoute,
				getItemUrl: props.getItemUrl,
			}}
		>
			{props.children}
		</Context.Provider>
	);
}

export const useThreatDashboardContext = () => React.useContext(Context);
