import React, { useEffect, useState } from 'react';
import { LoadingPrimary } from '@citrite/citrix-ui';
import { cx } from '@emotion/css';
import { AxiosError } from 'axios';
import { useShareFileUserContext } from '../../contexts/shareFileUserContext';
import { useThreatDashboardContext } from '../../contexts/ThreatDashboardContext';
import { FolderDayActivity } from '../../data/diagnosticTypes';
import { AnalyticsClientService } from '../../data/services/analyticsClient';
import { t } from '../../utils';
import { ErrorState } from '../../utils/ErrorState/ErrorState';
import { DayFolderActivties } from './DayFolderActivities';
import { EmptyStateStyles } from './styles';

export interface FolderActivityRightPane {
	folderId: string;
	ActivityDays: number;
}

enum ActivitiyViewState {
	loading,
	errorLoading,
	errorLoadingWithRetry,
	successfullyLoaded,
}

export const FolderActivityRightPane = (props: FolderActivityRightPane) => {
	const managedUser = useThreatDashboardContext().managedUser;
	const { sfAccount } = useShareFileUserContext();
	const [viewState, setViewState] = useState<ActivitiyViewState>(
		ActivitiyViewState.loading
	);
	const [activites, setActivities] = useState<Array<FolderDayActivity>>(
		Array<FolderDayActivity>()
	);

	useEffect(
		() => {
			fetchActivities();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.folderId, props.ActivityDays]
	);

	const fetchActivities = () => {
		setViewState(ActivitiyViewState.loading);
		AnalyticsClientService.getFolderActivity(
			managedUser.Id,
			sfAccount.Id,
			props.folderId,
			props.ActivityDays
		)
			.then(response => {
				if (response == null || typeof response === 'undefined') {
					return;
				}

				setActivities(response);
				setViewState(ActivitiyViewState.successfullyLoaded);
			})
			.catch(ex => {
				var exception = ex as AxiosError;

				if (
					exception.response?.status == 408 ||
					exception.response?.status == 502 ||
					exception.response?.status == 503 ||
					exception.response?.status == 504
				) {
					setViewState(ActivitiyViewState.errorLoadingWithRetry);
				} else {
					setViewState(ActivitiyViewState.errorLoading);
				}
			});
	};

	const getDateFromString = (dateStr: string) => {
		const [day, month, year] = dateStr.split('/');

		return new Date(+year, +month - 1, +day);
	};

	const getViewState = () => {
		switch (viewState) {
			case ActivitiyViewState.loading:
				return <LoadingPrimary data-testid="loader" />;
			case ActivitiyViewState.errorLoading:
				return (
					<ErrorState
						className={cx(EmptyStateStyles)}
						message={t('threat-visibility:diagnostics:error_loading_activity')}
					/>
				);
			case ActivitiyViewState.errorLoadingWithRetry:
				return (
					<ErrorState
						className={cx(EmptyStateStyles)}
						message={t('threat-visibility:diagnostics:error_loading_activity_retry')}
						retry={true}
						retryCallback={fetchActivities}
					/>
				);
			case ActivitiyViewState.successfullyLoaded:
				return (
					<div>
						{activites.map(dayActivity => {
							return (
								<DayFolderActivties
									key={dayActivity.Date}
									FolderId={props.folderId}
									Day={getDateFromString(dayActivity.Date)}
									Activities={dayActivity.Activities || []}
								/>
							);
						})}
					</div>
				);
		}
	};

	return <div className="rightPane">{getViewState()}</div>;
};
