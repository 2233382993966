import React, { useState } from 'react';
import { ItemIcon } from '@citrite/citrix-files-ui';
import {
	CalendarIcon,
	sortDirections,
	Table,
	TBody,
	TD,
	THead,
} from '@citrite/citrix-ui';
import { createUserPreferences } from '@citrite/sf-api';
import { SortParams } from '@citrite/sf-sorting';
import { v4 } from 'uuid';
import { useShareFileUserContext } from '../../contexts/shareFileUserContext';
import { useThreatDashboardContext } from '../../contexts/ThreatDashboardContext';
import { ActivityDetails, getActivityTypeString } from '../../data/diagnosticTypes';
import {
	AnalyticsClientService,
	PagingParams,
} from '../../data/services/analyticsClient';
import { t } from '../../utils';
import { formatDate, formatTime } from '../../utils/dateTimeformatters';
import { Paging } from '../../utils/Paging/Paging';

export interface DayFolderActivitiesProps {
	FolderId: string;
	Day: Date;
	Activities: Array<ActivityDetails>;
}

export const DefaultPageFolderDayActivities = 4;

export const DayFolderActivties = (props: DayFolderActivitiesProps) => {
	const [activities, setActivities] = useState(props.Activities);
	const { sfUser, sfAccount } = useShareFileUserContext();
	const managedUser = useThreatDashboardContext().managedUser;
	const [sortParams, setSortParams] = useState<SortParams>({
		key: t('threat-visibility:threat_dashboard.time'),
		direction: sortDirections.descending,
		pageSize: DefaultPageFolderDayActivities,
		skip: 0,
		top: DefaultPageFolderDayActivities,
	});

	const pageChildren = async (sortParams: SortParams) => {
		const loadedActivities = await AnalyticsClientService.getFolderActivityForDate(
			managedUser.Id,
			sfAccount.Id,
			props.FolderId,
			props.Day,
			{
				top: sortParams.pageSize,
				skip: sortParams.skip,
			} as PagingParams
		);

		setActivities([...activities, ...loadedActivities]);
		setSortParams(sortParams);
	};

	return (
		<>
			<div className="dayHeader">
				<CalendarIcon size={16} />
				<span
					data-testid="activity-date"
					style={{
						color: props.Activities.length == 0 ? '#767676' : '#171717',
					}}
				>
					{formatDate(props.Day, sfUser.Preferences || createUserPreferences())}
					{props.Activities.length == 0 && (
						<span> - {t('threat-visibility:diagnostics:no_activity_date')}</span>
					)}
				</span>
			</div>
			<Table>
				<THead>
					<th className="timeColumn">{t('threat-visibility:diagnostics:time')}</th>
					<th className="fileColumn">{t('threat-visibility:diagnostics:file')}</th>
					<th className="actionColumn">{t('threat-visibility:diagnostics:action')}</th>
					<th className="locationColumn">
						{t('threat-visibility:diagnostics:location')}
					</th>
				</THead>
				<TBody>
					{activities?.map(activity => {
						return (
							<tr key={v4()} className="itemRow">
								<TD
									label={t('threat-visibility:diagnostics:time')}
									data-testid="activity-time"
								>
									{formatTime(
										activity.TimeStamp,
										sfUser.Preferences || createUserPreferences()
									)}
								</TD>
								<TD
									label={t('threat-visibility:diagnostics:file')}
									data-testid="activity-file"
								>
									<div className="fileInfo">
										<ItemIcon fileName={activity.ItemName} size={24} />
										<div data-testid="activity-file-name" title={activity.ItemName}>
											{activity.ItemName}
										</div>
									</div>
								</TD>
								<TD
									label={t('threat-visibility:diagnostics:action')}
									data-testid="activity-action"
								>
									{getActivityTypeString(activity.Activity)}
								</TD>
								<TD
									label={t('threat-visibility:diagnostics:location')}
									data-testid="activity-location"
								>
									{activity.Location}
								</TD>
							</tr>
						);
					})}
				</TBody>
			</Table>
			<Paging
				itemCount={activities.length}
				sortParams={sortParams}
				pageChildren={pageChildren}
			/>
		</>
	);
};
