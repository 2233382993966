import React, { useEffect } from 'react';
import { ModalProps, withModals } from '@citrite/citrix-ui';
import { UserActivityModal } from './UserActivityModal';

export interface UserActivityModalWrapperProps extends ModalProps {
	showModal: any;
	setShowModal: any;
}

export const _UserActivityModalWrapper = (props: UserActivityModalWrapperProps) => {
	const onClose = () => {
		props.setShowModal(false);
	};

	useEffect(
		() => {
			props.showModal(
				<UserActivityModal {...props} onClose={onClose} data-testid="modalWrapper" />
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return <></>;
};

export const UserActivityModalWrapper = withModals(_UserActivityModalWrapper);
