import { breakpoints, Tab } from '@citrite/citrix-ui';
import { css } from '@emotion/css';
import styled from '@emotion/styled';

export const threatDashboardStyles = css`
	width: 60%;
	margin-right: 30px;

	.dashboardArea {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 400px;
	}

	.tableHeaders {
		text-align: left;
		font-weight: 500;
		font-size: 16px;
	}

	.locationColumn {
		width: 25%;
	}

	.threatDetailColumn {
		width: 50%;
	}

	.timestampColumn {
		width: 25%;
	}

	.threatRow {
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
		margin-top: 8px;
		margin-bottom: 8px;

		&:hover {
			background-color: #f3f3f3;
		}
	}

	th {
		height: 46px;
		box-sizing: border-box;
		font-weight: 500;
		font-size: 16px;
		padding: 6px;
		text-align: left;

		&:first-of-type {
			padding-left: 15px;
		}
	}

	.threatDetails {
		white-space: normal;
		padding-top: 8px;
		padding-bottom: 8px;
		line-height: 24px;
	}

	.subDetailText {
		color: #767676;
		font-size: 12px;
		line-height: 14px;
	}

	.fileInfo {
		display: flex;
		flex-direction: row;
		align-items: center;
		line-height: 14px;
		margin: 6px 0 10px;
	}

	.fileName {
		font-size: 12px;
		margin-left: 6px;
	}

	.folderLink {
		text-decoration: none;
		line-height: 14px;
		height: 14px;
		white-space: nowrap;
	}

	.folderPath {
		overflow: hidden;
		text-overflow: ellipsis;
		direction: rtl;

		&:hover {
			color: #5d5d5d;
			font-weight: 500;
		}
	}

	.error-msg {
		margin: 20px 0;
		color: #171717;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
	}
`;

export const StyledActions = styled.div`
	margin-top: 18px;
	margin-right: 30px;
	width: 30%;
	min-width: 300px;
	font-size: 14px;

	${breakpoints.onMobile} {
		margin: 24px 0;
		width: 100%;
	}
`;

export const StyledTab = styled(Tab)`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;
