import React, { useEffect } from 'react';
import {
	Button,
	EmptyLockedIcon,
	layout,
	Modal,
	ModalBody,
	SectionTitle,
	Text,
	withModals,
} from '@sharefiledev/flow-web';
import { Flex } from '../Flex';
import { t } from '../translate';

export interface SignInAgainModalProps {
	showModal: any;
	setShowModal: any;
}

const _SignInAgainModal = (props: SignInAgainModalProps) => {
	const path = encodeURIComponent(location.pathname);

	useEffect(
		() => {
			props.showModal(
				<Modal
					{...props}
					closeOnEscape={false}
					closeOnOverlayClick={false}
					data-testid="sign-in-again-modal"
				>
					<ModalBody>
						<Flex column>
							<Flex column gap={layout.mediumLargeSpace} align="center">
								<EmptyLockedIcon size={120} />
								<Flex column gap={layout.mediumSpace} align="center">
									<SectionTitle>
										{t('threat-visibility:legacyTokenInterceptor.title')}
									</SectionTitle>
									<Text>{t('threat-visibility:legacyTokenInterceptor.message')}</Text>
								</Flex>
								<Button.Primary
									data-analytics-id={'analytics_LegacyTokenReauth'}
									onClick={() => location.assign(`/login?cmd=route&id=${path}`)}
								>
									{t('threat-visibility:legacyTokenInterceptor.buttonText')}
								</Button.Primary>
							</Flex>
						</Flex>
					</ModalBody>
				</Modal>
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return <></>;
};

export const SignInAgainModal = withModals(_SignInAgainModal);
