import { css } from '@emotion/css';

export const ActivityBannerStyles = css`
	display: flex;
	padding: 12px 16px;
	background-color: #e1f4ff;
	width: 100%;
	align-items: center;
	border-radius: 4px;
	margin-bottom: 20px;
	justify-content: space-between;

	.bannerContent {
		display: flex;
		align-items: center;
		width: calc(100% - 178px);
	}

	.icon {
		margin-right: 20px;
	}

	.bannerText {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
	}

	.actionBtn {
		font-size: 14px;
		line-height: 16px;
		font-weight: 600;
		width: 148px;
	}
`;

export const ActivityModalStyles = css`
	max-width: 100% !important;
	width: 80% !important;

	.filterDiv {
		margin-top: 52px;
		padding-left: 40px;
		padding-bottom: 16px;
	}

	.activityContent {
		border-top: 1px solid #d9d9d9;
		margin-top: 0;
		margin-bottom: 0;
		padding-left: 0;
		padding-right: 0;
		height: 600px;
		display: flex;
	}

	.leftPane {
		width: 30%;
		border-right: 1px solid #d9d9d9;
		height: 600px;
		overflow-y: auto;
		min-width: 250px;
	}

	.rightPane {
		width: 70%;
		height: 568px;
		overflow-y: auto;
		padding: 16px 40px;
		min-width: 500px;
		overflow-x: auto;
	}

	.emptyStateArea {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
	}

	.folderItem {
		padding: 20px 24px 20px 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;

		&:hover {
			background-color: #f3f3f3;
		}
	}

	.folderInfo {
		margin-right: 12px;
		width: 85%;
		text-align: left;
	}

	.folderDiv {
		display: flex;
		align-items: center;
		margin-bottom: 4px;
	}

	.icon {
		margin-right: 8px;
	}

	.folderName {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.folderActivityCount {
		color: white;
		background-color: #0045db;
		border-radius: 12px;
		line-height: 24px;
		padding: 0 12px;
	}

	.dayHeader {
		margin-top: 20px;
		margin-bottom: 12px;
		font-size: 14px;
		font-weight: 400;
		display: flex;
		align-items: center;
	}

	.dayHeader > span {
		margin-right: 12px;
	}

	th {
		height: 40px;
		box-sizing: border-box;
		font-weight: 600;
		font-size: 14px;
		padding: 6px;
		text-align: left;

		&:first-of-type {
			padding-left: 15px;
		}
	}

	td {
		padding-top: 12px;
		padding-bottom: 12px;
	}

	.timeColumn {
		width: 15%;
	}

	.fileColumn {
		width: 55%;
	}

	.locationColumn {
		width: 15%;
	}

	.actionColumn {
		width: 15%;
	}

	.fileInfo {
		display: flex;
		align-items: center;
		line-height: 24px;
		overflow-x: hidden;
	}

	.fileInfo > span {
		margin-right: 12px;
	}

	.fileInfo > div {
		overflow-x: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.itemRow {
		&:hover {
			background-color: #f3f3f3;
		}
	}
`;

export const EmptyStateStyles = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
`;
