import React from 'react';
import {
	LoginThreat,
	MalwareThreat,
	ThreatInfo,
	ThreatType,
} from '../../data/analyticsTypes';
import { FrequentLoginFailureThreatDetails } from './FrequentLoginFailureThreat';
import { MalwareThreatDetails } from './MalwareThreatDetails';
import { UnusualLocationThreatDetails } from './UnusualLocationThreat';
import { UnusualLocationDeviceThreatDetails } from './UnusualLocationWithDeviceThreat';

export const DetailedThreatInfo = (threat: ThreatInfo) => {
	function getThreatDetailsDiv(threat: ThreatInfo) {
		switch (threat.ThreatType) {
			case ThreatType.MALWARE_UPLOAD:
				return <MalwareThreatDetails {...(threat.ThreatDetails as MalwareThreat)} />;
			case ThreatType.FREQUENT_LOGIN_FAILURE:
				return <FrequentLoginFailureThreatDetails />;
			case ThreatType.UNUSUAL_LOCATION:
				return <UnusualLocationThreatDetails />;
			case ThreatType.UNUSUAL_LOCATION_DEVICE:
				return (
					<UnusualLocationDeviceThreatDetails
						{...(threat.ThreatDetails as LoginThreat)}
					/>
				);
		}
	}

	return <div className="threatDetails">{getThreatDetailsDiv(threat)}</div>;
};
