import './BouncingLoader.scss';
import React from 'react';

export const BouncingDotsLoader = () => {
	return (
		<>
			<div className="bouncing-loader">
				<div></div>
				<div></div>
				<div></div>
			</div>
		</>
	);
};
