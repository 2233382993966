export enum TimeFilter {
	Last24Hours = 1,
	Last7Days = 7,
	Last30Days = 30,
}

export enum AlertLevel {
	Warning = 0,
	Alert = 1,
}

export interface ThreatsCountData {
	ThreatsCount: number;
	AlertLevel: AlertLevel;
	NumOfDays: number;
}

export interface FolderActivity {
	FolderId: string;
	FolderName: string;
	ActivityCount: number;
}

export enum ActivityType {
	FileUpload = 'File.Upload',
	FileDownload = 'File.Download',
	FileDelete = 'File.Delete',
}

export const getActivityTypeString = (activity: ActivityType): string => {
	switch (activity) {
		case ActivityType.FileUpload:
			return 'Upload';
		case ActivityType.FileDownload:
			return 'Download';
		case ActivityType.FileDelete:
			return 'Delete';
	}
};

export interface ActivityDetails {
	TimeStamp: Date;
	ItemId: string;
	ItemName: string;
	Activity: ActivityType;
	Location: string;
}

export interface FolderDayActivity {
	Date: string;
	Activities: Array<ActivityDetails>;
}
