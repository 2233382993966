import axios, { AxiosRequestConfig } from 'axios';
import { getLogger } from '../../logger';

interface Configuration {
	baseUrl: string;
	analyticsApiPath: string;
	headers: {};
}

const configuration: Configuration = {
	baseUrl: window.origin,
	analyticsApiPath: '/io/analytics/v1',
	headers: {
		'X-BFF-CSRF': 'true',
	},
};

export interface RequestConfig extends AxiosRequestConfig {
	path?: string;
}

export async function apiProxy<ResponseType>(
	requestConfig: RequestConfig,
	requestHeaders = {}
): Promise<ResponseType> {
	const axiosRequestConfig: AxiosRequestConfig = {
		url: configuration.baseUrl + configuration.analyticsApiPath + requestConfig.path,
		method: requestConfig.method,
		params: requestConfig.params,
		headers: {
			...configuration.headers,
			...requestHeaders,
		},
	};

	getLogger().logInfo('API request config', {
		requestConfig: JSON.stringify(axiosRequestConfig),
	});

	const response = await axios.request<ResponseType>(axiosRequestConfig);

	if (response.status < 200 || response.status > 399) {
		getLogger().logError(new Error(JSON.stringify(response)), {
			customMessage: 'Error in API response',
		});
		throw new Error(JSON.stringify(response));
	}

	return response.data;
}
