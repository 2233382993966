import React from 'react';
import { t } from '../../utils';

export const FrequentLoginFailureThreatDetails = () => {
	return (
		<div data-testid="freqLoginThreat">
			{t('threat-visibility:threat_dashboard.frequent_login_failure')}
		</div>
	);
};
